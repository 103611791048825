/* eslint-disable no-use-before-define */
import { ref } from '@vue/composition-api'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'

export default function useWorkplaceHandler(props, emit) {
  const toast = useToast()

  const stateOptions = ref([])
  const cityOptions = ref([])
  const payDayOptions = ref(new Array(30).fill(1).map((_, index) => ({
    label: index + 1,
    value: index + 1,
  })))
  const paymentMonthOptions = ref(new Array(2).fill(1).map((_, index) => ({
    label: index + 1,
    value: index + 1,
  })))
  const selectedState = ref('')
  const selectedCity = ref('')

  const createDuty = () => ({
    duty_type_id: '',
    remuneration: {
      6: 0,
      12: 0,
      24: 0,
    },
  })

  // const workplaceLocal = ref(JSON.parse(JSON.stringify(props.workplace.value)))

  const resetWorkplaceLocal = async () => {
    // workplaceLocal.value = JSON.parse(JSON.stringify(props.workplace.value))
    // const { address } = workplaceLocal.value
    // if (address[0] && address[0].city.state.id) {
    //   await fetchCities(address[0].city.state.id)
    // }
  }
  // watch(props.workplace, () => {
  //   resetWorkplaceLocal()
  // })

  // const getDuty = (duties, dutyHour) => {
  //   const dutyFound = duties.find(duty => duty.duration === dutyHour)
  //   return dutyFound ? dutyFound.remuneration / 100 : ''
  // }

  // watch(props.isWorkplaceHandlerSidebarActive, () => {
  //   if (workplaceLocal.value.id) {
  //     const {
  //       id, name, contact, phone, address, duties,
  //     } = props.workplace.value
  //     workplaceLocal.value = {
  //       id,
  //       name,
  //       contact,
  //       phone,
  //       address: {
  //         city_id: address[0].city.id,
  //         street: address[0].street,
  //         number: address[0].number,
  //         postal_code: address[0].postal_code,
  //         neighborhood: address[0].neighborhood,
  //         complement: address[0].complement || '',
  //       },
  //       duties: {
  //         6: getDuty(duties, 6),
  //         12: getDuty(duties, 12),
  //         24: getDuty(duties, 24),
  //       },
  //     }
  //     selectedCity.value = address[0].city.id
  //     selectedState.value = address[0].city.state.id
  //   }
  //
  //   if (!props.isWorkplaceHandlerSidebarActive.value) {
  //     emit('clear-workplace-data')
  //     selectedCity.value = ''
  //     selectedState.value = ''
  //   }
  // })
  //
  // watch(selectedCity, () => {
  //   if (selectedCity.value) {
  //     workplaceLocal.value.address.city_id = selectedCity.value
  //   }
  // })

  const onSubmit = () => {
    // const submittedValue = {
    //   id: workplaceLocal.value.id,
    //   name: workplaceLocal.value.name,
    //   contact: workplaceLocal.value.contact,
    //   phone: workplaceLocal.value.phone,
    //   address: workplaceLocal.value.address,
    //   duties: {
    //     6: Number(workplaceLocal.value.duties[6] * 100),
    //     12: Number(workplaceLocal.value.duties[12] * 100),
    //     24: Number(workplaceLocal.value.duties[24] * 100),
    //   },
    // }
    // submittedValue.address.complement = submittedValue.address.complement || null
    // store.dispatch(`app-workplace/${submittedValue.id ? 'updateWorkplace' : 'addWorkplace'}`, JSON.parse(JSON.stringify(submittedValue)))
    //   .then(() => {
    //     emit('refetch-data')
    //     emit('update:is-workplace-handler-sidebar-active', false)
    //   }).catch(() => {
    //     toast({
    //       component: ToastificationContent,
    //       props: {
    //         title: `Erro ao ${submittedValue.id ? 'editar' : 'adicionar'} unidade`,
    //         icon: 'AlertTriangleIcon',
    //         variant: 'danger',
    //       },
    //     })
    //   })
  }

  const removeWorkplace = id => {
    store.dispatch('app-workplace/removeWorkplace', id)
      .then(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Unidade removida com sucesso!',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        emit('refetch-data')
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Não foi possível remover a unidade',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const fetchStates = () => store.dispatch('app-workplace/fetchStates')
    .then(response => {
      stateOptions.value = response.data.data.map(state => ({
        value: state.id,
        text: state.abbreviation,
      })).sort((a, b) => a.text.localeCompare(b.text))
    }).catch(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Erro ao obter estados',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })

  const fetchCities = stateId => store.dispatch('app-workplace/fetchCities', { stateId })
    .then(response => {
      cityOptions.value = response.data.data.map(state => ({
        value: state.id,
        text: state.name,
      }))
      // selectedCity.value = cityOptions.value[0].value
      return cityOptions.value
    })
    .catch(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Erro ao obter cidades',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })

  return {
    // workplaceLocal,
    createDuty,
    resetWorkplaceLocal,
    onSubmit,
    fetchStates,
    fetchCities,
    stateOptions,
    cityOptions,
    selectedState,
    selectedCity,
    removeWorkplace,
    payDayOptions,
    paymentMonthOptions,
  }
}
